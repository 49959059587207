import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_4";
import BlockPrincipal from "../components/block/Block_6";
import BlockSecondary from "../components/block/Block_3";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[23] : rpdata?.stock?.[3]}`}
        />
        <BlockPrincipal
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image2={rpdata?.gallery?.length > 24 ? rpdata?.gallery?.[24] : rpdata?.stock?.[4]}
          image1={rpdata?.gallery?.length > 25 ? rpdata?.gallery?.[25] : rpdata?.stock?.[5]}
          image3={rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[20] : rpdata?.stock?.[0]}
          image4={rpdata?.gallery?.length > 21 ? rpdata?.gallery?.[21] : rpdata?.stock?.[1]}
          corte={rpdata?.stock}
          listsAbout
        />
        <ValuesOne
          image={rpdata?.gallery?.length > 22 ? rpdata?.gallery?.[22] : rpdata?.stock?.[2]}
          image1={rpdata.gallery?.length > 23 ? rpdata?.gallery?.[23] : rpdata?.stock?.[3]}
          image2={rpdata.gallery?.length > 26 ? rpdata?.gallery?.[26] : rpdata?.stock?.[6]} />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[8]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.gallery?.length > 27 ? rpdata?.gallery?.[28] : rpdata?.stock?.[17]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
